import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./components/layouts/loading/loading";
import React, { Suspense } from "react";
import "./styles/style.css";
const Login = React.lazy(() => import("./pages/auth/login"));
const Protected = React.lazy(() => import("./pages/auth/Protected"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const AidRequests = React.lazy(() => import("./pages/AidRequests"));
const ViewAidRequests = React.lazy(() => import("./pages/ViewAidRequests"));
const Products = React.lazy(() => import("./pages/Products"));
const Faq = React.lazy(() => import("./pages/Faq"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const GeneralDonation = React.lazy(() => import("./components/Donation/GeneralDonation"));
const SpecialDonation = React.lazy(() => import("./pages/SpecialDonation"));

const Newsletter = React.lazy(() => import("./pages/Newsletter"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Subscription = React.lazy(() => import("./pages/Subscription"));
const Donation = React.lazy(() => import("./pages/Donation"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const About = React.lazy(() => import("./pages/About"));
const MainPage = React.lazy(() => import("./pages/MainPage"));
const Orders = React.lazy(() => import("./pages/Orders"));
const Donors = React.lazy(() => import("./pages/Donors"));
const MyDeleted = React.lazy(() => import("./pages/MyDeleted"));

function App() {
  const { isAuthLoading } = useSelector((state) => state.AuthSlice);
  const { is_Locations_loading } = useSelector((state) => state.LocationsSlice);
  const { is_Contact_loading } = useSelector((state) => state.ContactSlice);
  return (
    <>
      {isAuthLoading && <Loader />}
      {is_Locations_loading && <Loader />}
      {is_Contact_loading && <Loader />}
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Trainers */}
          <Route element={<Protected />}>
            <Route path="*" element={<Dashboard />} />
            <Route path="/AidRequests" element={<AidRequests />} />
            <Route path="/ViewAidRequests/:id" element={<ViewAidRequests />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Faq" element={<Faq />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/Newsletter" element={<Newsletter />} />
            <Route path="/Subscription" element={<Subscription />} />
            <Route path="/Donation" element={<Donation />} />
            <Route path="/About" element={<About />} />
            <Route path="/Privacy" element={<Privacy />} />
            <Route path="/MainPage" element={<MainPage />} />
            <Route path="/Orders" element={<Orders />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Donors" element={<Donors />} />
            <Route path="/MyDeleted" element={<MyDeleted />} />
            <Route path="/GeneralDonation" element={<GeneralDonation />} />
            <Route path="/SpecialDonation/:id" element={<SpecialDonation />} />
            
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
