import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "./api";

// Async thunk to fetch "getEmirates"
export const getAidRequests = createAsyncThunk(
  "Locations/getAidRequests",
  async ({ limit }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/aid-requests/admin?limit=${limit}`
      );
      return response.data.data.aidRequests;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch children of a specific location
export const getAidRequestsId = createAsyncThunk(
  "Locations/getAidRequestsId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/aid-requests/admin/${id}`);
      return response.data.data.aidRequests;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAidRequests = createAsyncThunk(
  "Locations/deleteAidRequests",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/aid-requests/admin/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// ================================================================
// Async thunk to fetch "getEmirates"
export const getproduct = createAsyncThunk(
  "Locations/getproduct",
  async ({ limit, filter = "all" }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/product/admin?filter=${filter}&limit=${limit}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateProducts = createAsyncThunk(
  "Locations/updateProducts",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/product/admin/${id}`, data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteproduct = createAsyncThunk(
  "Locations/deleteproduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/product/admin/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createproduct = createAsyncThunk(
  "Locations/createproduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/product/admin", data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getRestoreproduct = createAsyncThunk(
  "Locations/getRestoreproduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/product/admin/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// ================================================================
// Async thunk to fetch "faqs"
export const getfaqs = createAsyncThunk(
  "Locations/getfaqs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/faqs/admin");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatefaqs = createAsyncThunk(
  "Locations/updatefaqs",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/faqs/admin/${id}`, data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deletefaqs = createAsyncThunk(
  "Locations/deletefaqs",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/faqs/admin/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createfaqs = createAsyncThunk(
  "Locations/createfaqs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/faqs/admin", data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================================
// Async thunk to fetch "faqs"
export const getBlogs = createAsyncThunk(
  "Locations/getBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/blog");
      return response.data.data.blogs;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateBlogs = createAsyncThunk(
  "Locations/updateBlogs",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/blog/admin/${id}`, data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteBlogs = createAsyncThunk(
  "Locations/deleteBlogs",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/blog/admin/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createBlogs = createAsyncThunk(
  "Locations/createBlogs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/blog/admin", data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================================
// Async thunk to fetch "faqs"
export const getDonation = createAsyncThunk(
  "Locations/getDonation",
  async ({ limit, filter }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/donation-campaign/admin?filter=${filter}&limit=${limit}`
      );
      return response.data.data.donationCampaigns;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getRestoreDonation = createAsyncThunk(
  "Locations/getRestoreDonation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/donation-campaign/admin/${id}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateDonation = createAsyncThunk(
  "Locations/updateDonation",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/donation-campaign/admin/${id}`,
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteDonation = createAsyncThunk(
  "Locations/deleteDonation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/donation-campaign/admin/${id}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createDonation = createAsyncThunk(
  "Locations/createDonation",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/donation-campaign/admin",
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// ================================================================
// Async thunk to fetch "        `/privacy-policy/admin`"
export const getprivacy = createAsyncThunk(
  "Locations/getprivacy",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/privacy-policy/admin");
      return response.data.data.privacyPolicy;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateprivacy = createAsyncThunk(
  "Locations/updateprivacy",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/privacy-policy/admin/${id}`,
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteprivacy = createAsyncThunk(
  "Locations/deleteprivacy",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/privacy-policy/admin/${id}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createprivacy = createAsyncThunk(
  "Locations/createprivacy",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/privacy-policy/admin", data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================================

// ================================================================
// Async thunk to fetch "faqs"
export const getabout = createAsyncThunk(
  "Locations/getabout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/about-us");
      return response.data.data.aboutUs;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateabout = createAsyncThunk(
  "Locations/updateabout",
  async ({ date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/about-us/admin/`, date);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================================

export const updatehighlight = createAsyncThunk(
  "Locations/updatehighlight",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/about-us/admin/highlight/${id}`,
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deletehighlight = createAsyncThunk(
  "Locations/deletehighlight",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/about-us/admin/highlight/${id}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createhighlight = createAsyncThunk(
  "Locations/createhighlight",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/about-us/admin/highlight",
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// ================================================================

// Async thunk to fetch "/about-us/admin/highlight"
export const getMainPage = createAsyncThunk(
  "Locations/getMainPage",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/main-page");
      return response.data.data.page;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateMainPage = createAsyncThunk(
  "Locations/updateMainPage",
  async ({ date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/main-page/`, date);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updatecards = createAsyncThunk(
  "Locations/updatecards",
  async ({ id, date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/main-page/ask-aid/cards/${id}`,
        date
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createcards = createAsyncThunk(
  "Locations/createcards",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/main-page/ask-aid/cards",
        data
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deletecards = createAsyncThunk(
  "Locations/deletecards",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/main-page/ask-aid/cards/${id}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// ============================================================================

export const updateStats = createAsyncThunk(
  "Locations/updateStats",
  async ({ id, date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/main-page/stats/${id}`, date);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createStats = createAsyncThunk(
  "Locations/createStats",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/main-page/stats", data);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteStats = createAsyncThunk(
  "Locations/deleteStats",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/main-page/stats/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deletefiles = createAsyncThunk(
  "Locations/deletefiles",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/files/${id}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch "getEmirates"
export const getGeneralDonation = createAsyncThunk(
  "Locations/getGeneralDonation",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/donation-campaign/admin/general-donation`
      );
      return response.data.data.generalDonation;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateGeneralDonation = createAsyncThunk(
  "Locations/updateGeneralDonation",
  async ({ date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/donation-campaign/admin/general-donation`,
        date
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Async thunk to fetch "getEmirates"
export const getSpecialDonation = createAsyncThunk(
  "Locations/getSpecialDonation",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/donation-campaign/${id}`
      );
      return response.data.data.donationCampaign;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSpecialDonation = createAsyncThunk(
  "Locations/updateSpecialDonation",
  async ({ id , date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/donation-campaign/admin/${id}`,
        date
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const LocationsSlice = createSlice({
  name: "Locations",
  initialState: {
    is_Locations_loading: false,
    error: null,
    getAidRequestsData: null,
    getAidRequestsIdData: null,
    getproductData: null,
    getfaqsData: null,
    getMainPageData: null,
    getDonationData: null,
    getprivacyData: null,
    getBlogsData: null,
    getaboutData: null,
    getGeneralDonationData: null,
    getSpecialDonationData: null,
  },
  extraReducers: (builder) => {
    builder
      // Handle getAidRequestsId
      .addCase(getGeneralDonation.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getGeneralDonation.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getGeneralDonationData = action.payload;
      })
      .addCase(getGeneralDonation.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getSpecialDonation.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getSpecialDonation.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getSpecialDonationData = action.payload;
      })
      .addCase(getSpecialDonation.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getabout.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getabout.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getaboutData = action.payload;
      })
      .addCase(getabout.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getprivacy.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getprivacy.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getprivacyData = action.payload;
      })
      .addCase(getprivacy.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getBlogs.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getBlogsData = action.payload;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getproduct.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getproduct.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getproductData = action.payload;
      })
      .addCase(getproduct.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getfaqs.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getfaqs.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getfaqsData = action.payload;
      })
      .addCase(getfaqs.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getMainPage.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getMainPage.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getMainPageData = action.payload;
      })
      .addCase(getMainPage.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle Donation
      .addCase(getDonation.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getDonation.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getDonationData = action.payload;
      })
      .addCase(getDonation.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getAidRequestsId
      .addCase(getAidRequestsId.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getAidRequestsId.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getAidRequestsIdData = action.payload;
      })
      .addCase(getAidRequestsId.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      })
      // Handle getChildrenLocation
      .addCase(getAidRequests.pending, (state) => {
        state.is_Locations_loading = true;
      })
      .addCase(getAidRequests.fulfilled, (state, action) => {
        state.is_Locations_loading = false;
        state.getAidRequestsData = action.payload;
      })
      .addCase(getAidRequests.rejected, (state, action) => {
        state.is_Locations_loading = false;
        state.error = action.payload;
      });
  },
});

export default LocationsSlice.reducer;
